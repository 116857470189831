$primary-color: #1890ff; // 全局主色
$link-color: #1890ff; // 链接色
$success-color: #52c41a; // 成功色
$warning-color: #faad14; // 警告色
$error-color: #f5222d; // 错误色
$font-size-base: 14px; // 主字号
$heading-color: rgba(0, 0, 0, 0.85); // 标题色
$text-color: rgba(0, 0, 0, 0.65); // 主文本色
$text-color-secondary: rgba(0, 0, 0, 0.45); // 次文本色
$text-color-dark: rgba(#fff, 0.85);
$heading-color-dark: #fff;
$text-color-secondary-dark: rgba(255, 255, 255, 0.65);
$border-radius-base: 4px; // 组件/浮层圆角
//$border-color-base: #d9d9d9; // 边框色
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // 浮层阴影
$highlight-color: #f5222d;
$processing-color: blue;
$font-size-base: 14px;
$disabled-color: #e0e0e0;
$placeholder-text-color: #bfbfbf;
$panel-background-color: rgba(246, 248, 249, 1);

$listItem-active-bg: rgb(230, 230, 230);

$light-gray: #e0e0e0;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$gray-9: #616161;
$gray-8: #757575;
$gray-6: #bdbdbd;
$gray-3: #f5f5f5;
$gray-2: #fafafa;
$black: #0a0a0a;

// Background color for `<body>`
$body-background: #fff;
// Base background color for most components
$component-background: #fff;
$font-family: 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue',
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
  monospace;

$font-variant-base: tabular-nums;
$font-size-base: 14px;
$font-size-lg: $font-size-base + 2px;
$font-size-sm: 12px;
$line-height-base: 1.5;
$border-radius-base: 4px;
$border-radius-sm: 2px;

// vertical paddings
$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 12px; // Form controls and items
$padding-xs: 8px; // small items

// vertical padding for all form controls
$control-padding-horizontal: $padding-sm;
$control-padding-horizontal-sm: $padding-xs;

// The background colors for active and hover states for things like
// list items or table cells.
$item-active-bg: $primary-color;
$item-hover-bg: $primary-color;

// ICONFONT
$iconfont-css-prefix: anticon;

// LINK
$link-color: $primary-color;
$link-hover-color: $primary-color;
$link-active-color: $primary-color;
$link-decoration: none;
$link-hover-decoration: none;

// Animation
$ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
$ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// Border color
$border-color-base: hsl(0, 0%, 85%); // base border outline a component
$border-color-split: hsl(0, 0%, 91%); // split border inside a component
$border-width-base: 1px; // width of the border for a component
$border-style-base: solid; // style of a components border

// Outline
$outline-blur-size: 0;
$outline-width: 2px;
$outline-color: $primary-color;

$background-color-light: hsl(
  0,
  0%,
  98%
); // background of header and selected item
$background-color-base: hsl(0, 0%, 96%); // Default grey background color

// Disabled states
$disabled-bg: $background-color-base;
$disabled-color-dark: rgba(#fff, 0.35);

// Shadow
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-1-up: 0 -2px 8px $shadow-color;
$shadow-1-down: 0 2px 8px $shadow-color;
$shadow-1-left: -2px 0 8px $shadow-color;
$shadow-1-right: 2px 0 8px $shadow-color;
$shadow-2: 0 4px 12px $shadow-color;
$box-shadow-base: $shadow-1-down;

// Buttons
$btn-font-weight: 400;
$btn-border-radius-base: $border-radius-base;
$btn-border-radius-sm: $border-radius-base;

$btn-primary-color: #fff;
$btn-primary-bg: $primary-color;

$btn-default-color: $text-color;
$btn-default-bg: #fff;
$btn-default-border: $border-color-base;

$btn-danger-color: $error-color;
$btn-danger-bg: $background-color-base;
$btn-danger-border: $border-color-base;

$btn-disable-color: $disabled-color;
$btn-disable-bg: $disabled-bg;
$btn-disable-border: $border-color-base;

$btn-padding-base: 0 $padding-md - 1px;
$btn-font-size-lg: $font-size-lg;
$btn-font-size-sm: $font-size-base;
$btn-padding-lg: $btn-padding-base;
$btn-padding-sm: 0 $padding-xs - 1px;

$btn-height-base: 32px;
$btn-height-lg: 40px;
$btn-height-sm: 24px;

$btn-circle-size: $btn-height-base;
$btn-circle-size-lg: $btn-height-lg;
$btn-circle-size-sm: $btn-height-sm;

$btn-group-border: $primary-color;

// Checkbox
$checkbox-size: 16px;
$checkbox-color: $primary-color;
$checkbox-check-color: #fff;
$checkbox-border-width: $border-width-base;

// Radio
$radio-size: 16px;
$radio-dot-color: $primary-color;

// Radio buttons
$radio-button-bg: $btn-default-bg;
$radio-button-color: $btn-default-color;
$radio-button-hover-color: $primary-color;
$radio-button-active-color: $primary-color;

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large descktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);

// Grid system
$grid-columns: 24;
$grid-gutter-width: 0;

// Layout
$layout-body-background: #f0f2f5;
$layout-header-background: #001529;
$layout-footer-background: $layout-body-background;
$layout-header-height: 64px;
$layout-header-padding: 0 50px;
$layout-footer-padding: 24px 50px;
$layout-sider-background: $layout-header-background;
$layout-trigger-height: 48px;
$layout-trigger-background: #002140;
$layout-trigger-color: #fff;
$layout-zero-trigger-width: 36px;
$layout-zero-trigger-height: 42px;
// Layout light theme
$layout-sider-background-light: #fff;
$layout-trigger-background-light: #fff;
$layout-trigger-color-light: $text-color;

// z-index list
$zindex-affix: 10;
$zindex-back-top: 10;
$zindex-modal-mask: 1000;
$zindex-modal: 1000;
$zindex-notification: 1010;
$zindex-message: 1010;
$zindex-popover: 1030;
$zindex-picker: 1050;
$zindex-dropdown: 1050;
$zindex-tooltip: 1060;

// Animation
$animation-duration-slow: 0.3s; // Modal
$animation-duration-base: 0.2s;
$animation-duration-fast: 0.1s; // Tooltip

// Form
// ---
$label-required-color: $highlight-color;
$label-color: $heading-color;
$form-item-margin-bottom: 24px;
$form-item-trailing-colon: true;
$form-vertical-label-padding: 0 0 8px;
$form-vertical-label-margin: 0;

// Input
// ---
$input-height-base: 32px;
$input-height-lg: 40px;
$input-height-sm: 24px;
$input-padding-horizontal: $control-padding-horizontal - 1px;
$input-padding-horizontal-base: $input-padding-horizontal;
$input-padding-horizontal-sm: $control-padding-horizontal-sm - 1px;
$input-padding-horizontal-lg: $input-padding-horizontal;
$input-padding-vertical-base: 4px;
$input-padding-vertical-sm: 1px;
$input-padding-vertical-lg: 6px;
$input-placeholder-color: hsl(0, 0, 75%);
$input-color: $text-color;
$input-border-color: $border-color-base;
$input-bg: #fff;
$input-addon-bg: $background-color-light;
$input-hover-border-color: $primary-color;
$input-disabled-bg: $disabled-bg;
$input-outline-offset: 0 0;

// Tooltip
// ---
//* Tooltip max width
$tooltip-max-width: 250px;
//** Tooltip text color
$tooltip-color: #fff;
//** Tooltip background color
$tooltip-bg: rgba(0, 0, 0, 0.75);
//** Tooltip arrow width
$tooltip-arrow-width: 5px;
//** Tooltip distance with trigger
$tooltip-distance: $tooltip-arrow-width - 1px + 4px;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

// Popover
// ---
//** Popover body background color
$popover-bg: #fff;
//** Popover text color
$popover-color: $text-color;
//** Popover maximum width
$popover-min-width: 177px;
//** Popover arrow width
$popover-arrow-width: 6px;
//** Popover arrow color
$popover-arrow-color: $popover-bg;
//** Popover outer arrow width
//** Popover outer arrow color
$popover-arrow-outer-color: $popover-bg;
//** Popover distance with trigger
$popover-distance: $popover-arrow-width + 4px;

// Modal
// --
$modal-header-bg: $component-background;
$modal-mask-bg: rgba(0, 0, 0, 0.65);

// Progress
// --
$progress-default-color: $processing-color;
$progress-remaining-color: $background-color-base;
$progress-text-color: $text-color;

// Menu
// ---
$menu-inline-toplevel-item-height: 40px;
$menu-item-height: 40px;
$menu-collapsed-width: 80px;
$menu-bg: $component-background;
$menu-popup-bg: $component-background;
$menu-item-color: $text-color;
$menu-highlight-color: $primary-color;
$menu-item-active-bg: $item-active-bg;
$menu-item-active-border-width: 3px;
$menu-item-group-title-color: $text-color-secondary;
// dark theme
$menu-dark-color: $text-color-secondary-dark;
$menu-dark-bg: $layout-header-background;
$menu-dark-arrow-color: #fff;
$menu-dark-submenu-bg: #000c17;
$menu-dark-highlight-color: #fff;
$menu-dark-item-active-bg: $primary-color;

// Spin
// ---
$spin-dot-size-sm: 14px;
$spin-dot-size: 20px;
$spin-dot-size-lg: 32px;

// Table
// --
$table-header-bg: $background-color-light;
$table-header-color: $heading-color;
$table-header-sort-bg: $background-color-base;
$table-body-sort-bg: rgba(0, 0, 0, 0.01);
$table-row-hover-bg: $primary-color;
$table-selected-row-bg: #fafafa;
$table-expanded-row-bg: #fbfbfb;
$table-padding-vertical: 16px;
$table-padding-horizontal: 16px;

// Tag
// --
$tag-default-bg: $background-color-light;
$tag-default-color: $text-color;
$tag-font-size: $font-size-sm;

// TimePicker
// ---
$time-picker-panel-column-width: 56px;
$time-picker-panel-width: $time-picker-panel-column-width * 3;
$time-picker-selected-bg: $background-color-base;

// Carousel
// ---
$carousel-dot-width: 16px;
$carousel-dot-height: 3px;
$carousel-dot-active-width: 24px;

// Badge
// ---
$badge-height: 20px;
$badge-dot-size: 6px;
$badge-font-size: $font-size-sm;
$badge-font-weight: normal;
$badge-status-size: 6px;

// Rate
// ---
$rate-star-color: yellow;
$rate-star-bg: $border-color-split;

// Card
// ---
$card-head-color: $heading-color;
$card-head-background: transparent;
$card-head-padding: 16px;
$card-inner-head-padding: 12px;
$card-padding-base: 24px;
$card-padding-wider: 32px;
$card-actions-background: $background-color-light;
$card-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);

// Tabs
// ---
$tabs-card-head-background: $background-color-light;
$tabs-card-height: 40px;
$tabs-card-active-color: $primary-color;
$tabs-title-font-size: $font-size-base;
$tabs-title-font-size-lg: $font-size-lg;
$tabs-title-font-size-sm: $font-size-base;
$tabs-ink-bar-color: $primary-color;
$tabs-bar-margin: 0 0 16px 0;
$tabs-horizontal-margin: 0 32px 0 0;
$tabs-horizontal-padding: 12px 16px;
$tabs-vertical-padding: 8px 24px;
$tabs-vertical-margin: 0 0 16px 0;
$tabs-scrolling-size: 32px;
$tabs-highlight-color: $primary-color;
$tabs-hover-color: $primary-color;
$tabs-active-color: $primary-color;

// BackTop
// ---
$back-top-color: #fff;
$back-top-bg: $text-color-secondary;
$back-top-hover-bg: $text-color;

// Avatar
// ---
$avatar-size-base: 32px;
$avatar-size-lg: 40px;
$avatar-size-sm: 24px;
$avatar-font-size-base: 18px;
$avatar-font-size-lg: 24px;
$avatar-font-size-sm: 14px;
$avatar-bg: #ccc;
$avatar-color: #fff;
$avatar-border-radius: $border-radius-base;

// Switch
// ---
$switch-height: 22px;
$switch-sm-height: 16px;
$switch-sm-checked-margin-left: -($switch-sm-height - 3px);
$switch-disabled-opacity: 0.4;
$switch-color: $primary-color;

// Pagination
// ---
$pagination-item-size: 32px;
$pagination-item-size-sm: 24px;
$pagination-font-family: Arial;
$pagination-font-weight-active: 500;

// Breadcrumb
// ---
$breadcrumb-base-color: $text-color-secondary;
$breadcrumb-last-item-color: $text-color;
$breadcrumb-font-size: $font-size-base;
$breadcrumb-icon-font-size: $font-size-base;
$breadcrumb-link-color: $text-color-secondary;
$breadcrumb-link-color-hover: $primary-color;
$breadcrumb-separator-color: $text-color-secondary;
$breadcrumb-separator-margin: 0 $padding-xs;

// Tree
// ---
$tree-title-height: 24px;
$tree-child-padding: 18px;
$tree-directory-selected-color: #fff;
$tree-directory-selected-bg: $primary-color;

// Collapse
// ---
$collapse-header-padding: 12px 0 12px 40px;
$collapse-header-bg: $background-color-light;
$collapse-content-padding: $padding-md;
$collapse-content-bg: $component-background;

// Skeleton
// ---
$skeleton-color: #f2f2f2;

// Transfer
// ---
$transfer-disabled-bg: $disabled-bg;

// Message
// ---
$message-notice-content-padding: 10px 16px;

// Motion
// ---
$wave-animation-width: 6px;

// List
// ---
$list-empty-text-padding: $padding-md;
$list-item-padding: $padding-sm 0;
$list-item-content-margin: 0 0 $padding-md 0;
$list-item-meta-margin-bottom: $padding-md;
$list-item-meta-avatar-margin-right: $padding-md;
$list-item-meta-title-margin-bottom: $padding-sm;

%clearfix {
  *zoom: 1;
  &:before,
  &:after {
    content: '';
    display: table;
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

.link-anchor {
  cursor: pointer;
  color: $link-color;
}

.close-button {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  color: $placeholder-text-color;
  &:hover {
    color: $btn-danger-color;
  }
  &:before {
    line-height: 1;
    display: inline-block;
    content: '\00d7';
  }

  &.normal {
    &:hover {
      color: $text-color;
    }
  }
}

.menu-bar {
  padding: 8px 12px;
  .menu {
    cursor: pointer;
  }
}
