.top {
  text-align: center;
}

.header {
  line-height: 70px;
  a {
    text-decoration: none;
  }
}

.logo {
  height: 70px;
  vertical-align: top;
  //margin-right: 16px;
}

.title {
  font-size: 33px;
  color: #333;
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
}
.subTitle {
  font-size: 24px;
  color: #333;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 40px;
}
.desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 30px;
  margin-bottom: 40px;
}
