.topbar {
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background-color: #001529;
  color: #fff;
  &-title {
    font-size: 24px;
    color: #fff;
    font-weight: normal;
  }

  &__right {
    display: flex;
    align-items: center;

    a {
      margin: 0 16px;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  &__action {
    cursor: pointer;
    padding-left: 6px;
    display: inline-block;
    line-height: 36px;
  }
}

.menu {
  &-item-vertical {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    background-color: #e6f7ff;
    &.active {
      color: #1890ff;
    }
  }
}

.btn-link {
  cursor: pointer;
  color: #1890ff;
}
