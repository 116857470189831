.logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  transition: all 0.3s;
  overflow: hidden;
  img {
    display: inline-block;
    vertical-align: middle;
    height: 40px;
  }
  .site-title {
    color: rgba(243, 243, 243, 0.75);
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    margin: 0 0 0 12px;
    font-weight: 400;
  }
}
