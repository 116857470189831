@import '../../styles/theme';
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
}

.content {
  padding: 32px 0;
  flex: 1;
}

@media (min-width: $screen-md-min) {
  .container {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }

  .content {
    padding: 112px 0 24px 0;
  }
}

.main {
  width: 368px;
  margin: 0 auto;
  @media screen and (max-width: $screen-sm) {
    width: 95%;
  }

  .icon {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.2);
    margin-left: 16px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $primary-color;
    }
  }

  .other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .register {
      float: right;
    }
  }
}

.registerResult {
  :global {
    .anticon {
      font-size: 64px;
    }
  }
}

.resultTitle {
  margin-top: 32px;
  font-size: 20px;
  line-height: 28px;
}
.resultActions {
  margin-top: 40px;
  a + a {
    margin-left: 8px;
  }
}
