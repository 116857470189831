p {
  &.small {
    font-size: 0.875rem;
  }
  &.xsmall {
    font-size: 0.75rem;
  }
  em {
    font-weight: 600;
    font-style: normal;
  }
  &.gray {
    color: #9e9e9e;
  }
}
