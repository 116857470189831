@import '../../styles/theme';

.login {
  :global {
    .ant-tabs .ant-tabs-bar {
      border-bottom: 0;
      margin-bottom: 24px;
      text-align: center;
    }

    .ant-form-item {
      margin: 0 2px 24px;
    }
  }

  .getCaptcha {
    display: block;
    width: 100%;
  }

  .icon {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.2);
    margin-left: 16px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $primary-color;
    }
  }

  .other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .register {
      float: right;
    }
  }

  .prefixIcon {
    font-size: $font-size-base;
    color: $disabled-color;
  }

  .submit {
    width: 100%;
    margin-top: 24px;
  }
}
