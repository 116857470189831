.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column !important;
}

.list-item {
  line-height: 40px;
  border-bottom: 1px solid #e0e0e0;
  &.active {
    background: #e8f4ff;
  }
  & > list-item {
    line-height: 20px;
  }
}

.emptyContainer {
  padding: 100px 0;
  text-align: center;
}

.container {
  max-width: 75rem;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
  min-height: 600px;
}

.explore-container {
  min-width: 1024px;
  max-width: 1440px;
  width: 90%;
  margin: 12px auto;
  padding: 0 16px;

  .item-label {
    font-size: 16px;
    font-weight: 400;
    color: #8bb1c4;
  }
}

.preview-container {
  max-width: 1280px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.preview-content {
  transform-origin: left top;
  width: 1280px;
  &.mobile {
    width: 100%;
  }
}

.settings-container {
  max-width: 1280px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow: auto;
  min-height: calc(100vh - 96px);
  .leftmenu {
    width: 224px;
    border-right: 1px solid #e0e0e0;
  }

  .right {
    flex: 1;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    .title {
      font-size: 20px;
      color: #333;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }
}

.page {
  height: 100%;
  min-height: 100%;
  min-height: 100vh;
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.row {
  position: relative;
  display: flex;
  align-items: center;

  &.ld-row {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.align-top {
    align-items: flex-start !important;
  }

  &.align-middle {
    align-items: center !important;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.align-stretch {
    align-items: stretch !important;
  }

  &.align-center {
    justify-content: center !important;
  }

  &.align-right {
    justify-content: flex-end !important;
  }

  &.align-justify {
    justify-content: space-between !important;
  }
}

.shrink {
  flex-shrink: 0;
}

.column,
.columns,
.col {
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  &.expand {
    flex: 1;
  }
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.ph-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.ph-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pv-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pl-5 {
  padding-left: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-0 {
  margin-bottom: 0 !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mt-5 {
  margin-top: 2.5rem !important;
}
.mt-6 {
  margin-top: 3rem !important;
}
.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.ml-4 {
  margin-left: 2rem !important;
}

.ml-5 {
  margin-left: 2.5rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.span-1 {
  width: 8.3333%;
}

.span-2 {
  width: 16.6666%;
}

.span-3 {
  width: 25%;
}

.span-4 {
  width: 33.3333% !important;
}

.span-5 {
  width: 41.6666%;
}

.span-6 {
  width: 50%;
}

.span-7 {
  width: 58.3333%;
}

.span-8 {
  width: 66.6666%;
}

.span-9 {
  width: 75%;
}

.span-10 {
  width: 83.3333%;
}

.span-11 {
  width: 91.6666%;
}

.span-12 {
  width: 100%;
}

.full-screen {
  width: 100%;
  height: 100%;
  min-height: 100%;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
  }
}

$standardListHeight: 32px;

.list-item {
  &-container {
    padding: 5px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.5;
    background: transparent;
    cursor: pointer;

    &.active {
      background: $listItem-active-bg !important;
    }

    &:hover {
      background: lighten($listItem-active-bg, 3);
    }

    .item-title {
      color: rgb(33, 33, 33);
      font-weight: bold;
    }

    .img-container {
      width: 100%;
      img {
        max-width: 100%;
      }
    }

    .item-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 10px;
      padding-right: 15px;
    }

    .item-description {
      word-break: break-word;
    }

    .title-container {
      display: flex;
      flex: 1;
      align-items: center;
    }
  }

  &-left {
    margin-left: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-right {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  .item-container {
    display: flex;
    flex: 1;
    margin-left: 10px;
    padding-right: 15px;
    align-items: center;
  }

  .title-container {
    display: flex;
    flex: 1;
    align-items: center;
    height: $standardListHeight;
  }
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;

  .col {
    position: relative;
    display: flex;
    margin-bottom: 1.5rem;
    &:hover {
      .close-button1 {
        opacity: 1;
        pointer-events: auto;
      }
      .star-icon {
        right: 24px;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .mod-add {
    background-color: rgba(9, 45, 66, 0.08);
    padding: 2rem 2rem 2.25rem;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: #017ebc;
    }
  }
}

.card-item {
  height: 120px;
  position: relative;
  display: flex;
  width: 100%;
  background-color: #fff;
  padding: 16px 12px 13px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  will-change: transform;
  transition: transform 0.2s ease-in;
  .icon {
    margin-right: 5px;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-title {
    color: #333;
    font-size: 0.875rem;
    line-height: 1.25;
    font-weight: bolder;
    height: 2.125rem;
    overflow: hidden;
    word-break: break-all;
  }

  &-description {
    display: block;
    margin-bottom: 0;
    font-size: 0.75rem;
    line-height: 1.33rem;
    font-weight: 400;
    color: #999;
    transition: color 0.2s ease-in;
    will-change: color;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    will-change: opacity;
    transition: opacity 0.2s ease-in;
  }

  &::after {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    opacity: 1;
  }

  &:hover {
    transform: translateY(-1px);
  }
}

.list-container-wrap {
  padding: 0 16px 16px;
  & > & {
    padding: 0;
  }
}

.tabs-box {
  position: relative;
  background: #f5f6f7;
  text-align: center;
  border-bottom: 1px solid #dfe3e6;

  a {
    background-color: #dfe3e6;
    border: 1px solid #dfe3e6;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    display: inline-block;
    font-weight: 700;
    margin: 2px 2px 0;
    padding: 8px 20px;
    text-decoration: none;
    color: #17394d;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      a.active {
        background-color: #fff;
        border-color: #dfe3e6;
        cursor: default;
        margin-bottom: -1px;
        padding-bottom: 9px;
      }
    }
  }
}

.wrap-custom-option {
  position: absolute;
  left: 128px;
  top: 4px;
  display: none;

  &.show {
    display: block;
  }

  .textInput {
    text-align: center;
    width: 120px;
    border: none;
    border-bottom: 1px solid #e1f0f8;
    border-radius: 0;
    background: transparent;
    outline: none;
  }
}
